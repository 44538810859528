export const STUDENT_SUBSCRIPTION = 'STUDENT_SUBSCRIPTION';
export const UPDATE_STUDENT_SUBSCRIPTION = 'UPDATE_STUDENT_SUBSCRIPTION';
export const STUDENT_CURRENT_SUBSCRIPTION = 'STUDENT_CURRENT_SUBSCRIPTION';
export const UPDATE_STUDENT_CURRENT_SUBSCRIPTION =
  'UPDATE_STUDENT_CURRENT_SUBSCRIPTION';
export const STUDENT_SUBSCRIPTION_LIST = 'STUDENT_SUBSCRIPTION_LIST';
export const UPDATE_STUDENT_SUBSCRIPTION_LIST =
  'UPDATE_STUDENT_SUBSCRIPTION_LIST';
export const STUDENT_RENEW_SUBSCRIPTION = 'STUDENT_RENEW_SUBSCRIPTION';
export const UPDATE_STUDENT_RENEW_SUBSCRIPTION =
  'UPDATE_STUDENT_RENEW_SUBSCRIPTION';
export const STUDENT_UPDATE_SUBSCRIPTION = 'STUDENT_UPDATE_SUBSCRIPTION';
export const UPDATE_STUDENT_UPDATE_SUBSCRIPTION =
  'UPDATE_STUDENT_UPDATE_SUBSCRIPTION';

export const CREATE_SUBSCRIPTION_ORDER = 'CREATE_SUBSCRIPTION_ORDER';
export const UPDATE_CREATE_SUBSCRIPTION_ORDER =
  'UPDATE_CREATE_SUBSCRIPTION_ORDER';
export const CLEAR_SUBSCRIPTION_ORDER = 'CLEAR_SUBSCRIPTION_ORDER';

export const START_SUBSCRIPTION_PAYMENT = 'START_SUBSCRIPTION_PAYMENT';
export const UPDATE_START_SUBSCRIPTION_PAYMENT =
  'UPDATE_START_SUBSCRIPTION_PAYMENT';

export const APPLY_DISCOUNT = 'APPLY_DISCOUNT';
export const UPDATE_APPLY_DISCOUNT = 'UPDATE_APPLY_DISCOUNT';
export const NEW_APPLY_DISCOUNT = 'NEW_APPLY_DISCOUNT';
export const NEW_RENEW_APPLY_DISCOUNT = 'NEW_RENEW_APPLY_DISCOUNT';
export const UPDATE_NEW_APPLY_DISCOUNT = 'UPDATE_NEW_APPLY_DISCOUNT';
export const UPDATE_NEW_RENEW_APPLY_DISCOUNT =
  'UPDATE_NEW_RENEW_APPLY_DISCOUNT';
export const REMOVE_STUDENT_SUBSCRIPTION = 'REMOVE_STUDENT_SUBSCRIPTION';
export const UPDATE_REMOVE_STUDENT_SUBSCRIPTION =
  'UPDATE_REMOVE_STUDENT_SUBSCRIPTION';
export const PARENT_STUDENT_SUBSCRIPTION = 'PARENT_STUDENT_SUBSCRIPTION';
export const UPDATE_PARENT_STUDENT_SUBSCRIPTION =
  'UPDATE_PARENT_STUDENT_SUBSCRIPTION';
export const PARENT_STUDENT_DETAILS = 'PARENT_STUDENT_DETAILS';
export const UPDATE_PARENT_STUDENT_DETAILS = 'UPDATE_PARENT_STUDENT_DETAILS';
export const UPDATE_ADD_NEW_SUBSCRIPTION_DETAILS =
  'UPDATE_ADD_NEW_SUBSCRIPTION_DETAILS';
export const UPDATE_EXPIRED_SUBSCRIPTIONS = 'UPDATE_EXPIRED_SUBSCRIPTIONS';
