import React, { useEffect, useState } from 'react';
import { func, bool, object, array } from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import OnlineClass from './OnlineClass';
import MyClassesTitle from '../../../../components/dashboard/Rightsidepart/MyexamTitle';
import SubjectTabNew from '../MyClass/SubjectTabNew';
import Spinner from 'react-bootstrap/Spinner';
import config from '../../../../../helpers/config';

import ClassSlider from './ClassSlider';
import CoCurricularHistory from './CoCurricularHistory';
import { getMyCoCurricularClassesAction } from '../../../../../stores/TeacherDashboard/TeacherDashboardAction';
import { getStudentListCurricular } from '../../../../../stores/StudentData/StudentDataAction';
import newTeacherCoCurIcon from '../../../../../assets/icons/teacherIcon/co-curriculars.svg';

const CoCurricularActivities = ({
  lgShow,
  setLgShow,
  Login,
  myCoCurricularClasses,
  getMyCoCurricularClasses,
  getStudentListCurricularA,
  studentList,
}) => {
  const [classList, setClassList] = useState([]);
  const [classHistoryList, setClassHistoryList] = useState([]);
  const nameObjArr = [];
  const token = Login.data.accessToken;
  const [selectActivity, setSelectedActivity] = useState('');
  const [selectecClass, setSelectedClass] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [uniqueArr2, setUniqueArr2] = useState([]);
  const api = axios.create({
    baseURL: config.apiBaseUrl,
  });
  useEffect(() => {
    const data = {};
    setIsLoading(true);
    getMyCoCurricularClasses();
  }, []);
  
  // useEffect(() => {
  //   if (myCoCurricularClasses?.data[0].response.length > 0) {
  //     getStudentListCurricularA(
  //       myCoCurricularClasses?.data[0].response?.[0]?.scheduleInfo?._id
  //     );
  //   }
  // }, [selectecClass]);

  useEffect(() => {
    if (myCoCurricularClasses?.data[0]?.response?.length === 0) {
      setIsLoading(false);
      setClassList([]);
    }

    if (myCoCurricularClasses?.data[0].response.length > 0) {
      setClassList([]);
      const responseData = myCoCurricularClasses?.data[0]?.response.map(
        (data) => ({
          date: data?.scheduleInfo?.date,
          timeSlot: data?.scheduleInfo?.timeSlot,
          duration: data?.scheduleInfo?.duration,
          startTime: data?.scheduleInfo?.startTime,
          startUrl: data?.scheduleInfo?.startUrl,
          id: data?._id,
          subject: data?.activityInfo?.activityName,
          subjectId: data?.activityInfo?._id,
          grade: data?.gradeInfo?.name,
          numberOfStudents: data?.numOfStudents,
          scheduleId: data.scheduleInfo._id,
          icon: data?.icons?.image,
          timezone: data?.timezone,
          meetingId: data.scheduleInfo.meetingId,
          encryptedPassword: data.scheduleInfo.encryptedPassword,
        })
      );
      setClassList(responseData);
      setIsLoading(false);
    }
  }, [myCoCurricularClasses]);

  useEffect(() => {
    setClassHistoryList([]);
    api
      .post(
        '/cocurricular-online-class/teachers/history',
        {
          teacher: Login.data._id,
          activity: '',
          timeFrame: '',
          page_no: 1,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setClassHistoryList(res?.data?.data[0]?.response);
      })
      .catch((error) => console.log('error', error.message));
  }, []);

  useEffect(() => {
    if (classList?.length > 0) {
      let newArray = [];
      classList.map((myClass) => nameObjArr.push(myClass.subject));
      classList.map((myClass) =>
        newArray.push({ myClass: myClass.subject, grage: myClass.grade })
      );
      // const uniqueArr = [...new Set(nameObjArr)];
      const abc = newArray?.map((item) => {
        return `${item.grage}-${item.myClass}`;
      });
      setUniqueArr2([...new Set(abc)]);
    } else {
      setUniqueArr2([]);
      setSelectedActivity('');
    }
  }, [classList]);

  // const uniqueArrObj = uniqueArr.map((item) => ({ name: item }));

  const Loader = () => (
    <div
      style={{
        height: '100vh',
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation='border' variant='warning' />
    </div>
  );
  return (
    <div>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {classList.length > 0 ? <OnlineClass classList={classList} /> : ''}

          <div className='myexam-main'>
            <MyClassesTitle
              title={`Upcoming Co-curricular classes  (${
                // classList?.filter((item) => item.subject === selectActivity)
                //   ?.length
                myCoCurricularClasses &&
                myCoCurricularClasses?.data[0]?.count[0]?.count
                  ? myCoCurricularClasses?.data[0]?.count[0]?.count
                  : '0'
              })`}
              titleIcon={newTeacherCoCurIcon}
            />

            {classList.length > 0 ? (
              <SubjectTabNew
                data={uniqueArr2}
                setSelectedActivity={setSelectedActivity}
              />
            ) : (
              ''
            )}
            {classList?.filter((item) => item.subject === selectActivity)
              ?.length === 0 ? (
              <p className='noClassAva-text'>
                When new Co-curricular classes are allocated to you, they will
                get reflected here.
              </p>
            ) : (
              <ClassSlider
                myClassList={classList?.filter(
                  (item) => item.subject === selectActivity
                )}
                handleSubjectModal={setLgShow}
                showSubjectModal={lgShow}
                setSelectedClass={setSelectedClass}
                studentList={studentList}
              />
            )}

            <MyClassesTitle
              title={`Co-curricular classes history (${
                classHistoryList && selectActivity
                  ? classHistoryList?.filter(
                      (item) =>
                        item.activityInfo.activityName === selectActivity
                    )?.length
                  : classHistoryList?.length
              })`}
              titleIcon={newTeacherCoCurIcon}
            />
            {/* {
            classHistoryList?.filter(
              (item) => item.activityInfo.activityName === selectActivity
            )?.length > 0 ? (
              <div className='myexam-examlist'>
                {classHistoryList
                  ?.filter(
                    (item) => item.activityInfo.activityName === selectActivity
                  )
                  ?.map((data) => (
                    <CoCurricularHistory val={data} />
                  ))}
              </div>
            ) : (
              <h4 className='noClassAva-text'>
                Once you teach Co-curricular classes to students, it will get
                reflected here.
              </h4>
            )} */}

            {classHistoryList?.length > 0 && selectActivity ? (
              classHistoryList?.filter(
                (item) => item.activityInfo.activityName === selectActivity
              )?.length > 0 ? (
                <div className='myexam-examlist'>
                  {classHistoryList
                    ?.filter(
                      (item) =>
                        item.activityInfo.activityName === selectActivity
                    )
                    ?.map((data) => (
                      <CoCurricularHistory val={data} />
                    ))}
                </div>
              ) : (
                <h4 className='noClassAva-text'>
                  Once you teach Co-curricular classes to students, it will get
                  reflected here.
                </h4>
              )
            ) : classHistoryList?.length > 0 ? (
              <div className='myexam-examlist'>
                {classHistoryList?.map((data) => (
                  <CoCurricularHistory val={data} />
                ))}
              </div>
            ) : (
              <h4 className='noClassAva-text'>
                Once you teach Co-curricular classes to students, it will get
                reflected here.
              </h4>
            )}
          </div>
        </>
      )}
    </div>
  );
};

CoCurricularActivities.propTypes = {
  lgShow: bool.isRequired,
  setLgShow: func.isRequired,
  Login: object.isRequired,
  myCoCurricularClasses: array.isRequired,
  getMyCoCurricularClasses: func.isRequired,
};

const mapStateToProps = (state) => ({
  Login: state.Auth.Login,
  myCoCurricularClasses: state.TeacherDashboard.myCoCurricularClasses,
  studentList: state.StudentData.studentListCoCurricular,
});

const mapDispatchToProps = (dispatch) => ({
  getMyCoCurricularClasses: () => dispatch(getMyCoCurricularClassesAction()),
  getStudentListCurricularA: (data) => dispatch(getStudentListCurricular(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoCurricularActivities);
